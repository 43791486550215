import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, withRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';

const App = React.lazy(() => import('./App'));

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <BrowserRouter basename="/app">
        <Suspense fallback={<div>Loading...</div>}>
            <App />
        </Suspense>
    </BrowserRouter>
);